import { useContext, useEffect } from 'react';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { about } from '../../portfolio';
import './About.css';
import { ThemeContext } from '../../contexts/theme';
import formatDescription from '../../common/formatDescription';

const About = () => {
  const [{ themeName }] = useContext(ThemeContext);

  const { name, role, description, resume, social, lightPortrait, darkPortrait } = about;

  // Preload images
  useEffect(() => {
    const preloadImages = () => {
      const images = [lightPortrait, darkPortrait];
      images.forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    };

    preloadImages();
  }, [lightPortrait, darkPortrait]);

  // const formatDescription = (text) => {
  //   const parts = text.split(/(\*\*.*?\*\*)/g); // Split text by **...**
  //   return parts.map((part, index) => {
  //     const key = `${part}-${index}`; // Create a unique key
  //     if (part.startsWith('**') && part.endsWith('**')) {
  //       return <strong key={key}>{part.slice(2, -2)}</strong>; // Remove ** and wrap with <strong>
  //     }
  //     return <span key={key}>{part}</span>;
  //   });
  // };  

  return (
    <div className='about'>
      {/* Title section */}
      {name && (
        <h1 className='about__title'>
          Hi, I am <span className='about__name'>{name}.</span>
        </h1>
      )}

      <div className='about__content'>
        <div className='about__left'>
          {role && <h2 className='about__role'>An {role}.</h2>}
          <p className='about__desc'>
            {formatDescription(description)}
          </p>

          <div className='about__contact'>
            {resume && (
              <a href={resume} download={resume} target="_blank" rel="noopener noreferrer">
                <span type='button' className='btn btn--outline'>
                  Resume
                </span>
              </a>
            )}

            {social && (
              <>
                {social.github && (
                  <a
                    href={social.github}
                    aria-label='github'
                    className='link link--icon'
                  >
                    <GitHubIcon />
                  </a>
                )}

                {social.linkedin && (
                  <a
                    href={social.linkedin}
                    aria-label='linkedin'
                    className='link link--icon'
                  >
                    <LinkedInIcon />
                  </a>
                )}
              </>
            )}
          </div>
        </div>

        <div className='about__right'>
          <img src={themeName === "dark" ? darkPortrait : lightPortrait} alt="Profile" className='about__image' />
        </div>
      </div>
    </div>
  );
};

export default About;
