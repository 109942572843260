/* eslint-disable react/no-array-index-key */
import React from 'react';
import { timeline } from '../../portfolio';
import './Timeline.css';
import formatDescription from '../../common/formatDescription';

const Timeline = () => (
  <section id="experience" className="timeline-section section">
    <h2 className="section__title">My Experience</h2>
    <ul className="timeline">
      {timeline.map((item, index) => (
        <li key={index} className={`timeline-item ${index % 2 === 1 ? 'timeline-inverted' : ''}`}>
          <div className="timeline-badge">
            <img 
              src={item.type === 'work' ? 'briefcase.png' : 'cap.png'} 
              alt={item.type === 'work' ? 'briefcase' : 'cap'} 
            />
          </div>
          <div className="timeline-panel">
            <div className="timeline-heading">
              <h3 className="timeline-title">{item.title}</h3>
              <span className="timeline-company">{item.company} - {item.years}</span>
            </div>
            <div className="timeline-body">
              {item.lines.map((line, lineIndex) => (
                <p key={lineIndex}>{formatDescription(line)}</p>
              ))}
            </div>
          </div>
        </li>
      ))}
    </ul>
  </section>
);

export default Timeline;
