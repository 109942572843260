const formatDescription = (text) => {
    const parts = text.split(/(\*\*.*?\*\*)/g); // Split text by **...**
    return parts.map((part, index) => {
      const key = `${part}-${index}`; // Create a unique key
      if (part.startsWith('**') && part.endsWith('**')) {
        return <strong key={key}>{part.slice(2, -2)}</strong>; // Remove ** and wrap with <strong>
      }
      return <span key={key}>{part}</span>;
    });
  }; 

export default formatDescription;